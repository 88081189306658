import {Map, Record} from 'immutable';

export interface LoginState extends Map<string, any> {
    login: any;
    renewalDetails: any;

    loginLoading: boolean;
    loginLoaded: boolean;
    loginFailed: boolean;

    renewalDetailsLoading: boolean;
    renewalDetailsLoaded: boolean;
    renewalDetailsFailed: boolean;
}

export const LoginStateRecord = Record({
    login: [],
    renewalDetails: [],
    loginLoading: false,
    loginLoaded: false,
    loginFailed: false,

    renewalDetailsLoading: false,
    renewalDetailsLoaded: false,
    renewalDetailsFailed: false,
});
